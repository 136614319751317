import React, { useState, useEffect, useRef } from 'react';
import { name, titles } from '../constants';
import Footer from './Footer';
import TypingEffect from './TypingEffect';
import '../App.css'; 
import Profile_Photo from '../assets/Profile_Photo.jpg'; 
import WebVitalsDisplay from './WebVitalsDisplay'; // Import the new component
import reportWebVitals from '../reportWebVitals'; // Import the reportWebVitals function

const Home = () => {
    const ref = useRef(0);
    const [text, setText] = useState('');
    const [isNameLoaded, setIsNameLoaded] = useState(false);
    const [vitals, setVitals] = useState([]); // State for storing web vitals

    // Typing effect for the name
    useEffect(() => {
        const interval = setInterval(() => {
            if (ref.current < name.length) {
                ref.current++;
                setText((prevText) => prevText + name[ref.current - 1]);
            } else {
                clearInterval(interval);
                setIsNameLoaded(true); // Set flag to start title typing effect
            }
        }, 500);
        return () => clearInterval(interval);
    }, []);

    // Collect web vitals and update state
    useEffect(() => {
        reportWebVitals((metric) => {
            setVitals((prevVitals) => [...prevVitals, metric]);
        });
    }, []);

    return (
        <div className='area relative z-0 bg-black w-screen h-screen'>
            <ul className="circles">
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
            </ul>
            <div className='hero relative h-[calc(100vh)] flex justify-center items-center text-white' id='hero'>
                <div className='flex flex-col sm:flex-row items-center gap-8 text-center sm:text-left'>
                    {/* Profile Picture on the Left Side */}
                    <div 
                        className='w-36 h-36 sm:w-48 sm:h-48 md:w-60 md:h-60 rounded-full overflow-hidden transition-all duration-1000 transform scale-75 opacity-0 hover:scale-100 hover:opacity-100'
                        style={{ opacity: isNameLoaded ? 1 : 0, transform: isNameLoaded ? 'scale(1)' : 'scale(0.75)' }}
                    >
                        <img 
                            src={Profile_Photo} 
                            alt="Profile" 
                            className='w-full h-full object-cover'
                        />
                    </div>
                    {/* Text on the Right Side */}
                    <div className='pt-4 h-auto sm:h-36 backdrop-blur-sm rounded-3xl'>
                        <h1 className='text-4xl sm:text-5xl md:text-6xl font-extrabold mt-2'>
                            Hi, I'm&nbsp;<span className='text-yellow-200 font-extrabold'>{text}</span>
                        </h1>
                        {isNameLoaded && (
                            <div className='mt-3 text-lg sm:text-xl md:text-2xl'>
                                <p>
                                    I'm a <TypingEffect strings={titles} />
                                </p>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            {/* Web Vitals Display
            <WebVitalsDisplay vitals={vitals} /> */}

            <Footer />
        </div>
    );
}

export default Home;
