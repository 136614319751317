import React from "react";
import Dashboard from '../assets/Dashboard.png';
import Shopping_Trends_Dashboard from '../assets/Shopping_Trends_Dashboard.png';
import Neural_Networks_Architecture from '../assets/Neural_Networks_Architecture.png';
import Credit_Card_Fraud_Detection from '../assets/Credit_Card_Fraud_Detection.png';
import Content_Recommendation_System from '../assets/Content_Recommendation_System.png';
import Footer from './Footer';

const ProjectCard = ({ image, title, description, git, technologies }) => {
    return (
        <div className="max-w-sm sm:max-w-md md:max-w-lg bg-gray-900 border border-neutral-100 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
            {title === 'Shopping Trends in United States' && 
                <a href="#">
                    <img className="w-full rounded-t-lg h-auto object-cover" src={Shopping_Trends_Dashboard} alt="" />
                </a>
            }
            {title === 'End-to-End Spotify ETL using AWS' && 
                <a href="#">
                    <img className="w-full rounded-t-lg h-auto object-cover" src={Dashboard} alt="" />
                </a>
            }
            {title === 'Content Based Recommendation System' && 
                <a href="#">
                    <img className="w-full rounded-t-lg h-auto object-cover" src={Content_Recommendation_System} alt="" />
                </a>
            }
            {title === 'Neural-Network-Based-Digit-Recognotion-AI-Model' && 
                <a href="#">
                    <img className="w-full rounded-t-lg h-auto object-cover" src={Neural_Networks_Architecture} alt="" />
                </a>
            }
            {title === 'Credit Card Fraud Detection' && 
                <a href="#">
                    <img className="w-full rounded-t-lg h-auto object-cover" src={Credit_Card_Fraud_Detection} alt="" />
                </a>
            }
            <div className="p-4 sm:p-6">
                <a href="#">
                    <h5 className="text-xl sm:text-2xl md:text-3xl font-bold tracking-tight text-white bg-clip-text text-transparent bg-gradient-to-r from-yellow-200 to-pink-500">
                        {title}
                    </h5>
                </a>
                <p className="font-normal text-sm sm:text-base md:text-lg text-gray-300 dark:text-gray-400">{description}</p>
            </div>
            <div className='m-2 sm:m-4 lg:m-6 flex flex-col sm:flex-row justify-between items-start'>
                <div className='flex flex-wrap gap-2 pl-2'>
                    {technologies.map((tag, index) => (
                        <p key={`${index}-${tag}`} className='text-xs sm:text-sm text-blue-500'>
                            #{tag}
                        </p>
                    ))}
                </div>
                {title === 'Shopping Trends in United States' ? (
                    <a href="https://public.tableau.com/app/profile/avinash.ranga6264/viz/ShoppingTrendsinUnitedStates/Dashboard1" className="text-red-300 border border-gray-200 rounded-lg shadow p-1 sm:p-2 lg:p-3 hover:text-green-500 duration-300 mt-2 sm:mt-0">
                        Tableau
                    </a>
                ) : (
                    <a href={git} className="text-red-300 border border-gray-200 rounded-lg shadow p-1 sm:p-2 lg:p-3 hover:text-green-500 duration-300 mt-2 sm:mt-0">
                        GitHub
                    </a>
                )}
            </div>
        </div>
    );
};

const Projects = () => {
    return (
        <div className="flex flex-col min-h-screen bg-black">
            <div className="flex-1 flex flex-wrap gap-7 justify-center items-center m-4 sm:m-8 lg:m-12 p-4 sm:p-8 lg:p-12">
                {project.map((item, index) => (
                    <ProjectCard
                        key={index}
                        image={item.image}
                        title={item.title}
                        description={item.description}
                        git={item.git}
                        technologies={item.technologies}
                    />
                ))}
            </div>
            <Footer />
        </div>
    );
};

export const project = [
    {
        title: 'End-to-End Spotify ETL using AWS',
        description: 'This project demonstrates the process of building an ETL pipeline from an API as data source to create a smooth flow of automated job in data collection, update the pipeline and build a dash board using AWS cloud service.',
        image: Dashboard,
        git: 'https://github.com/avinash8898/Spotify-ETL-Using-AWS/tree/main',
        technologies: ['Python', 'AWS S3', 'AWS Crawler', 'AWS Glue', 'AWS Athena', 'SQLite3']
    },
    {
        title: 'Shopping Trends in United States',
        description: 'Exploring U.S. consumer behavior through interactive visualizations, revealing shopping patterns and trends across regions and product categories.',
        image: Shopping_Trends_Dashboard,
        git: "https://public.tableau.com/app/profile/avinash.ranga6264/viz/ShoppingTrendsinUnitedStates/Dashboard1",
        technologies: ['Data Analysis', 'Tableau', 'Tableau Public', 'Data Visualization']
    },
    {
        title: 'Credit Card Fraud Detection',
        description: 'Developed a credit card fraud detection system utilizing Logistic Regression for classification, PCA for dimensionality reduction, and statistical analysis to identify fraudulent transactions with high accuracy. Implemented robust data preprocessing to handle imbalanced datasets and improve model performance. Utilized techniques like cross-validation, hyperparameter tuning, and evaluation metrics (precision, recall, F1-score) to optimize the model and ensure reliable detection.',
        image: Credit_Card_Fraud_Detection,
        git: 'https://github.com/avinash8898/Credit-Card-Fraud-Detection',
        technologies: ['Machine Learning', 'Logistic Regression', 'Microsoft Excel', 'Statistical Analysis',]
    },
    {
        title: 'Content Based Recommendation System',
        description: 'This project implements a Content-Based Recommendation System using TF-IDF Vectorization, difflib, and cosine similarity. It recommends items by analyzing their content and popularity, matching user preferences with similar items.',
        image: Credit_Card_Fraud_Detection,
        git: 'https://github.com/avinash8898/Content-Based-Recommendation-System',
        technologies: ['Machine Learning', 'Vectorization', 'Closest Match', 'Cosine Similarity',]
    },
    {
        title: 'Neural-Network-Based-Digit-Recognotion-AI-Model',
        description: 'Training a neural network to classify handwritten digits from the MNIST dataset, using convolutional layers demonstrating deep learning techniques for effective image recognition.',
        image: Neural_Networks_Architecture,
        git: 'https://github.com/avinash8898/Neural-Network-Based-Digit-Recognotion-AI-Model',
        technologies: ['Deep Learning', 'Neural Networks', 'Machine Learning', 'Data Science']
    }
];

export default Projects;